export const collapsibleUpcomingCustomerDashboard = () => {
  var upcoming = document.getElementById("customer-dash-upcoming");
  var upcomingB2c = document.getElementById("cust-upcoming-b2c");
  var upcomingB2b = document.getElementById("cust-upcoming-b2b");
  var upcomingArrowUp = document.getElementById('cust-upcoming-arrow-up');
  var upcomingArrowDown = document.getElementById('cust-upcoming-arrow-down');

  if ((upcomingB2c) && (upcomingB2b)) {
    upcoming.addEventListener('click', () => {
      if (upcomingB2c.style.display === "block") {
        upcomingB2c.style.display = "none";
        upcomingB2b.style.display = "none";
        upcomingArrowUp.style.display = "none";
        upcomingArrowDown.style.display = "block";
      } else {
        upcomingB2c.style.display = "block";
        upcomingB2b.style.display = "block";
        upcomingArrowUp.style.display = "block";
        upcomingArrowDown.style.display = "none";
      };
    });
  };
};

export const collapsiblePastCustomerDashboard = () => {
  var past = document.getElementById("cust-dash-past");
  var pastB2c = document.getElementById("cust-past-b2c");
  var pastB2b = document.getElementById("cust-past-b2b");
  var pastArrowUp = document.getElementById('cust-past-arrow-up');
  var pastArrowDown = document.getElementById('cust-past-arrow-down');

  if ((pastB2c) && (pastB2b)) {
    past.addEventListener('click', () => {
      if (pastB2c.style.display === "block") {
        pastB2c.style.display = "none";
        pastB2b.style.display = "none";
        pastArrowUp.style.display = "none";
        pastArrowDown.style.display = "block";
      } else {
        pastB2c.style.display = "block";
        pastB2b.style.display = "block";
        pastArrowUp.style.display = "block";
        pastArrowDown.style.display = "none";
      };
    });
  };
};

export const collapsibleRefundsCustomerDashboard = () => {
  var refunds = document.getElementById("cust-dash-refunds");
  var refundsB2c = document.getElementById("cust-refunds-b2c");
  var refundsB2b = document.getElementById("cust-refunds-b2b");
  var requestedRefunds = document.getElementById("cust-requested-refunds");
  var refundsArrowUp = document.getElementById('cust-refunds-arrow-up');
  var refundsArrowDown = document.getElementById('cust-refunds-arrow-down');

  if ((refundsB2c) && (refundsB2b) && (requestedRefunds)) {
    refunds.addEventListener('click', () => {
      if (refundsB2c.style.display === "block") {
        refundsB2c.style.display = "none";
        refundsB2b.style.display = "none";
        requestedRefunds.style.display = "none";
        refundsArrowUp.style.display = "none";
        refundsArrowDown.style.display = "block";
      } else {
        refundsB2c.style.display = "block";
        refundsB2b.style.display = "block";
        requestedRefunds.style.display = "block";
        refundsArrowUp.style.display = "block";
        refundsArrowDown.style.display = "none";
      };
    });
  } else if ((refundsB2b) && (requestedRefunds)) {
    refunds.addEventListener('click', () => {
      if (refundsB2b.style.display === "block") {
        refundsB2b.style.display = "none";
        requestedRefunds.style.display = "none";
        refundsArrowUp.style.display = "none";
        refundsArrowDown.style.display = "block";
      } else {
        refundsB2b.style.display = "block";
        requestedRefunds.style.display = "block";
        refundsArrowUp.style.display = "block";
        refundsArrowDown.style.display = "none";
      };
    });
  } else if ((refundsB2c) && (refundsB2b)) {
    refunds.addEventListener('click', () => {
      if (refundsB2c.style.display === "block") {
        refundsB2c.style.display = "none";
        refundsB2b.style.display = "none";
        refundsArrowUp.style.display = "none";
        refundsArrowDown.style.display = "block";
      } else {
        refundsB2c.style.display = "block";
        refundsB2b.style.display = "block";
        refundsArrowUp.style.display = "block";
        refundsArrowDown.style.display = "none";
      };
    });
  };
};

