export const disableOfferingQuantity = () => {
  // Function to bind event listeners to checkboxes and pill buttons
  const bindEventListeners = () => {
    // Function to toggle active class on pill buttons
    const toggleActiveClass = (button) => {
      document.querySelectorAll('.price-option-pill').forEach(pill => {
        pill.classList.remove('active'); // Remove active class from all pills
      });
      button.classList.add('active'); // Add active class to clicked pill
    };

    // Bind event listeners for checkboxes
    document.querySelectorAll('[id$="_quantity"]').forEach(input => {
      const day = input.id.replace('_quantity', '');
      const checkbox = document.getElementById(day);
      if (checkbox) {
        checkbox.addEventListener('change', function() {
          input.disabled = !this.checked;
        });
      }
    });

    // Bind event listeners for pill buttons
    const priceOptionsPills = document.querySelector('.price-options-pills');
    if (priceOptionsPills) {
        priceOptionsPills.addEventListener('click', async (event) => {
        const button = event.target.closest('.price-option-pill');
        if (button) {
          toggleActiveClass(button); // Toggle active class on clicked pill
          const optionId = button.getAttribute('data-option-id');
          const productId = button.getAttribute('data-product-id');

          try {
            const response = await fetch(`/b2b_offerings/${productId}/form?selected_option_id=${optionId}`, {
              headers: {
                'X-Requested-With': 'XMLHttpRequest', // Important for Rails to recognize AJAX requests
              },
            });
            if (!response.ok) throw new Error('Network response was not ok.');
            const html = await response.text();
            // Update the target for HTML content insertion to the new wrapper
            document.querySelector('.product-settings-b2b-offerings-form').innerHTML = html;
          } catch (error) {
            console.error('Error fetching form:', error);
          }
        }
      });
    };
  };

  // Ensure event listeners are bound correctly regardless of when the script loads
  if (document.readyState === 'loading') {
    document.addEventListener('DOMContentLoaded', bindEventListeners);
  } else {
    bindEventListeners();
  }
};
