const retailerValuePropsReadMore = () => {
  var vPropReadMoreBtn = document.getElementById("retailers-value-prop-read-more");
  var valueProps = document.getElementsByClassName("retailers-text");
  var firstValueProp = document.getElementsByClassName("retailers-text")[0];

  if ($('body').data('locale') == 'de') {
    var more = 'MEHR ERFAHREN';
    var less = 'WENIGER ANZEIGEN';
  };

  if ($('body').data('locale') == 'en') {
    var more = 'FIND OUT MORE';
    var less = 'SHOW LESS';
  };

  if (vPropReadMoreBtn) {
    vPropReadMoreBtn.addEventListener("click", function() {
      if (firstValueProp.classList.contains('retailers-text-active')) {
        for (let element of valueProps) {
          element.classList.remove("retailers-text-active");
        };
        vPropReadMoreBtn.innerHTML = `${more}`;
      } else {
        for (let element of valueProps) {
          element.classList.add("retailers-text-active");
        };
        vPropReadMoreBtn.innerHTML = `${less}`;
      };
    });
  };
};

const retailerWhyReadMore = () => {
  var whyReadMoreBtn = document.getElementById("why-read-more-btn");
  var whyMoreText = document.getElementsByClassName("retailers-why-collapsible")[0];

  if ($('body').data('locale') == 'de') {
  var more = 'Mehr';
  var less = 'Weniger';
  };

  if ($('body').data('locale') == 'en') {
    var more = 'More';
    var less = 'Less';
  };

  if (whyReadMoreBtn) {
    whyReadMoreBtn.addEventListener("click", function() {
      if (whyMoreText.classList.contains('retailers-text-active')) {
        whyMoreText.classList.remove("retailers-text-active");
        whyReadMoreBtn.innerHTML = `${more}`;
      } else {
        whyMoreText.classList.add("retailers-text-active");
        whyReadMoreBtn.innerHTML = `${less}`;
      };
    });
  };
};

const retailerFeaturesReadMore = () => {
  var featuresReadMoreBtn = document.getElementById("retailers-features-read-more");
  var featuresText = document.getElementsByClassName("retailer-feature-text");
  var firstFeature = document.getElementsByClassName("retailer-feature-text")[0];

  if ($('body').data('locale') == 'de') {
    var more = 'MEHR ERFAHREN';
    var less = 'WENIGER ANZEIGEN';
  };

  if ($('body').data('locale') == 'en') {
    var more = 'FIND OUT MORE';
    var less = 'SHOW LESS';
  };

  if (featuresReadMoreBtn) {
    featuresReadMoreBtn.addEventListener("click", function() {
      if (firstFeature.classList.contains('retailers-text-active')) {
        for (let element of featuresText) {
          element.classList.remove("retailers-text-active");
        };
        featuresReadMoreBtn.innerHTML = `${more}`;
      } else {
        for (let element of featuresText) {
          element.classList.add("retailers-text-active");
        };
        featuresReadMoreBtn.innerHTML = `${less}`;
      };
    });
  };
};

const b2bProductPageFeaturesReadMore = () => {
  var b2bFeaturesReadMoreBtn = document.getElementById("b2b-prod-features-read-more");
  var b2bFeaturesTexts = document.getElementsByClassName("b2b-prod-features-text");
  var firstb2bFeaturesText = document.getElementsByClassName("b2b-prod-features-text")[0];

  if ($('body').data('locale') == 'de') {
    var more = 'MEHR ERFAHREN';
    var less = 'WENIGER ANZEIGEN';
  };

  if ($('body').data('locale') == 'en') {
    var more = 'FIND OUT MORE';
    var less = 'SHOW LESS';
  };

  if (b2bFeaturesReadMoreBtn) {
    b2bFeaturesReadMoreBtn.addEventListener("click", function() {
      if (firstb2bFeaturesText.classList.contains('retailers-text-active')) {
        for (let element of b2bFeaturesTexts) {
          element.classList.remove("retailers-text-active");
        };
        b2bFeaturesReadMoreBtn.innerHTML = `${more}`;
      } else {
        for (let element of b2bFeaturesTexts) {
          element.classList.add("retailers-text-active");
        };
        b2bFeaturesReadMoreBtn.innerHTML = `${less}`;
      };
    });
  };
};

const b2cProductPageFeaturesReadMore = () => {
  var b2cFeaturesReadMoreBtn = document.getElementById("b2c-prod-features-read-more");
  var b2cFeaturesTexts = document.getElementsByClassName("b2c-prod-features-text");
  var firstb2cFeaturesText = document.getElementsByClassName("b2c-prod-features-text")[0];

  if ($('body').data('locale') == 'de') {
    var more = 'MEHR ERFAHREN';
    var less = 'WENIGER ANZEIGEN';
  };

  if ($('body').data('locale') == 'en') {
    var more = 'FIND OUT MORE';
    var less = 'SHOW LESS';
  };

  if (b2cFeaturesReadMoreBtn) {
    b2cFeaturesReadMoreBtn.addEventListener("click", function() {
      if (firstb2cFeaturesText.classList.contains('retailers-text-active')) {
        for (let element of b2cFeaturesTexts) {
          element.classList.remove("retailers-text-active");
        };
        b2cFeaturesReadMoreBtn.innerHTML = `${more}`;
      } else {
        for (let element of b2cFeaturesTexts)  {
          element.classList.add("retailers-text-active");
        };
        b2cFeaturesReadMoreBtn.innerHTML = `${less}`;
      };
    });
  };
};

const retailerPageVPropReadMore = () => {
  var retailersVPropReadMoreBtn = document.getElementById("retailers-v-prop-read-more");
  var retailersVPropTexts = document.getElementsByClassName("retailers-v-prop-text");
  var firstretailersVPropText = document.getElementsByClassName("retailers-v-prop-text")[0];

  if ($('body').data('locale') == 'de') {
    var more = 'MEHR ERFAHREN';
    var less = 'WENIGER ANZEIGEN';
  };

  if ($('body').data('locale') == 'en') {
    var more = 'FIND OUT MORE';
    var less = 'SHOW LESS';
  };

  if (retailersVPropReadMoreBtn) {
    retailersVPropReadMoreBtn.addEventListener("click", function() {
      if (firstretailersVPropText.classList.contains('retailers-text-active')) {
        for (let element of retailersVPropTexts) {
          element.classList.remove("retailers-text-active");
        };
        retailersVPropReadMoreBtn.innerHTML = `${more}`;
      } else {
        for (let element of retailersVPropTexts) {
          element.classList.add("retailers-text-active");
        };
        retailersVPropReadMoreBtn.innerHTML = `${less}`;
      };
    });
  };
};

export { retailerValuePropsReadMore, retailerWhyReadMore, retailerFeaturesReadMore, b2bProductPageFeaturesReadMore, b2cProductPageFeaturesReadMore, retailerPageVPropReadMore };
