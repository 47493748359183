export const showFilters = () => {

  var filterBox = document.getElementById('search-results-filter');
  var arrowDown = document.getElementById('arrow-down');
  var arrowUp = document.getElementById('arrow-up');

  if (filterBox) {
    arrowDown.onclick = function(){
      filterBox.style.display = "block";
      arrowUp.style.display = "block";
      arrowDown.style.display = "none";
    };
    arrowUp.onclick = function(){
      filterBox.style.display = "none";
      arrowUp.style.display = "none";
      arrowDown.style.display = "block";
    };
  };
};


export const showFiltersStore = () => {

  var filterBox = document.getElementById('filter-box-show');
  var arrowDown = document.getElementById('arrow-down');
  var arrowUp = document.getElementById('arrow-up');

  if (filterBox) {
    arrowDown.onclick = function(){
      filterBox.style.display = "block";
      arrowUp.style.display = "block";
      arrowDown.style.display = "none";
    };
    arrowUp.onclick = function(){
      filterBox.style.display = "none";
      arrowUp.style.display = "none";
      arrowDown.style.display = "block";
    };
  };
};
