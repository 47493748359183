export const b2bOfferingsUpdateQuantityToggle = () => {
  const allEditBtns = document.getElementsByClassName('b2b-off-edit');


  if (allEditBtns.length > 0) {
    for (let item of allEditBtns) {
      item.addEventListener('click', () => {
        let id = item.id.slice(9)
        let form = document.getElementById(`edit-b2b-off-form-${id}`)
        let quantity = document.getElementById(`b2b-off-${id}-quantity`)
        let closeIcon = document.getElementById(`b2b-edit-close-${id}`)
        let trashIcon = document.getElementById(`b2b-off-destroy-${id}`)

        item.style.display = "none";
        trashIcon.style.display = "none"
        form.style.display = "block";
        closeIcon.style.display = "block";
        if (quantity) {
          quantity.style.display = "none";
        };

        if (closeIcon) {
          console.log("inside");
          closeIcon.addEventListener('click', () => {
            if (closeIcon.style.display === "block") {
              item.style.display = "block";
              trashIcon.style.display = "block"
              form.style.display = "none";
              closeIcon.style.display = "none";
              if (quantity) {
                quantity.style.display = "block";
              };
            }
          });
        };
      });
    }
  };
};
