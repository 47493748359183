export const collapsibleUpcomingRetailerDashboard = () => {
  var upcoming = document.getElementById("owner-dash-upcoming");
  var upcomingB2c = document.getElementById("upcoming-b2c");
  var upcomingB2b = document.getElementById("upcoming-b2b");
  var upcomingArrowUp = document.getElementById('upcoming-arrow-up');
  var upcomingArrowDown = document.getElementById('upcoming-arrow-down');

  if ((upcomingB2c) && (upcomingB2b)) {
    upcoming.addEventListener('click', () => {
      console.log('clicked')
      if (upcomingB2c.style.display === "block") {
        upcomingB2c.style.display = "none";
        upcomingB2b.style.display = "none";
        upcomingArrowUp.style.display = "none";
        upcomingArrowDown.style.display = "block";
      } else {
        upcomingB2c.style.display = "block";
        upcomingB2b.style.display = "block";
        upcomingArrowUp.style.display = "block";
        upcomingArrowDown.style.display = "none";
      };
    });
  };

  $('.owner-dash-nav-link').click(function() {
      // Remove 'page' parameter from URL
      var newUrl = removeURLParameter(window.location.href, 'page');
      // Reload the page without the 'page' parameter
      window.location.href = newUrl;
  });

  // Function to remove a parameter from URL
  function removeURLParameter(url, parameter) {
      // Prefer using the URL constructor for parsing
      var urlObject = new URL(url);
      // Remove the parameter
      urlObject.searchParams.delete(parameter);
      // Return the modified URL
      return urlObject.href;
  };
};

export const collapsiblePastRetailerDashboard = () => {
  var past = document.getElementById("owner-dash-past");
  var pastB2c = document.getElementById("past-b2c");
  var pastB2b = document.getElementById("past-b2b");
  var pastArrowUp = document.getElementById('past-arrow-up');
  var pastArrowDown = document.getElementById('past-arrow-down');

  if ((pastB2c) && (pastB2b)) {
    past.addEventListener('click', () => {
      if (pastB2c.style.display === "block") {
        pastB2c.style.display = "none";
        pastB2b.style.display = "none";
        pastArrowUp.style.display = "none";
        pastArrowDown.style.display = "block";
      } else {
        pastB2c.style.display = "block";
        pastB2b.style.display = "block";
        pastArrowUp.style.display = "block";
        pastArrowDown.style.display = "none";
      };
    });
  };
};

export const collapsibleRefundsRetailerDashboard = () => {
  var refunds = document.getElementById("owner-dash-refunds");
  var refundsB2c = document.getElementById("refunds-b2c");
  var refundsB2b = document.getElementById("refunds-b2b");
  var requestedRefunds = document.getElementById("requested-refunds");
  var refundsArrowUp = document.getElementById('refunds-arrow-up');
  var refundsArrowDown = document.getElementById('refunds-arrow-down');

  if ((refundsB2c) && (refundsB2b) && (requestedRefunds)) {
    refunds.addEventListener('click', () => {
      if (refundsB2c.style.display === "block") {
        refundsB2c.style.display = "none";
        refundsB2b.style.display = "none";
        requestedRefunds.style.display = "none";
        refundsArrowUp.style.display = "none";
        refundsArrowDown.style.display = "block";
      } else {
        refundsB2c.style.display = "block";
        refundsB2b.style.display = "block";
        requestedRefunds.style.display = "block";
        refundsArrowUp.style.display = "block";
        refundsArrowDown.style.display = "none";
      };
    });
  } else if ((refundsB2b) && (requestedRefunds)) {
    refunds.addEventListener('click', () => {
      if (refundsB2b.style.display === "block") {
        refundsB2b.style.display = "none";
        requestedRefunds.style.display = "none";
        refundsArrowUp.style.display = "none";
        refundsArrowDown.style.display = "block";
      } else {
        refundsB2b.style.display = "block";
        requestedRefunds.style.display = "block";
        refundsArrowUp.style.display = "block";
        refundsArrowDown.style.display = "none";
      };
    });
  } else if ((refundsB2c) && (refundsB2b)) {
    refunds.addEventListener('click', () => {
      if (refundsB2c.style.display === "block") {
        refundsB2c.style.display = "none";
        refundsB2b.style.display = "none";
        refundsArrowUp.style.display = "none";
        refundsArrowDown.style.display = "block";
      } else {
        refundsB2c.style.display = "block";
        refundsB2b.style.display = "block";
        refundsArrowUp.style.display = "block";
        refundsArrowDown.style.display = "none";
      };
    });
  };
};
