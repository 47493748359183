export const editPriceOptions = () => {
  var addNewBtn = document.getElementById('add-new-price-option-btn');
  var newFormDiv = document.getElementById('new-price-option-form');
  if (addNewBtn) {
    addNewBtn.addEventListener('click', function(e) {
      e.preventDefault();
      newFormDiv.style.display = 'block';
      addNewBtn.style.display = 'none';
    });
  }
};


