export const launchAcceptanceModal = () => {
  if ($('#acceptance-modal')) {
    $('#acceptance-modal').modal('show')
  }
};

export const launchLimitedOfferModal = () => {
  if ($('#limited-offer-modal')) {
    setTimeout(function(){
       $('#limited-offer-modal').modal('show')
    }, 20000);
  }
};
