export const openingHoursValue = () => {
  var opens0 = document.getElementById('store_opening_hours_attributes_0_opens');
  var closes0 = document.getElementById('store_opening_hours_attributes_0_closes');
  var checkbox0 = document.getElementById('store_opening_hours_attributes_0__destroy');

  if (opens0 && closes0) {
    opens0.addEventListener('change', (event) => {
      if (opens0.value !== "" && closes0.value !== "") {
        checkbox0.value = 0
      };
      if (opens0.value === "" || closes0.value === "") {
        checkbox0.value = 1
      };
    });
    closes0.addEventListener('change', (event) => {
      console.log("changed_closes")
      if (opens0.value !== "" && closes0.value !== "") {
        checkbox0.value = 0
      };
      if (opens0.value === "" || closes0.value === "") {
        checkbox0.value = 1
      };
    });
  };

  var opens1 = document.getElementById('store_opening_hours_attributes_1_opens');
  var closes1 = document.getElementById('store_opening_hours_attributes_1_closes');
  var checkbox1 = document.getElementById('store_opening_hours_attributes_1__destroy');

  if (opens1 && closes1) {
    opens1.addEventListener('change', (event) => {
      if (opens1.value !== "" && closes1.value !== "") {
        checkbox1.value = 0
      };
      if (opens1.value === "" || closes1.value === "") {
        checkbox1.value = 1
      };
    });
    closes1.addEventListener('change', (event) => {
      console.log("changed_closes")
      if (opens1.value !== "" && closes1.value !== "") {
        checkbox1.value = 0
      };
      if (opens1.value === "" || closes1.value === "") {
        checkbox1.value = 1
      };
    });
  };

  var opens2 = document.getElementById('store_opening_hours_attributes_2_opens');
  var closes2 = document.getElementById('store_opening_hours_attributes_2_closes');
  var checkbox2 = document.getElementById('store_opening_hours_attributes_2__destroy');

  if (opens2 && closes2) {
    opens2.addEventListener('change', (event) => {
      if (opens2.value !== "" && closes2.value !== "") {
        checkbox2.value = 0
      };
      if (opens2.value === "" || closes2.value === "") {
        checkbox2.value = 1
      };
    });
    closes2.addEventListener('change', (event) => {
      console.log("changed_closes")
      if (opens2.value !== "" && closes2.value !== "") {
        checkbox2.value = 0
      };
      if (opens2.value === "" || closes2.value === "") {
        checkbox2.value = 1
      };
    });
  };

  var opens3 = document.getElementById('store_opening_hours_attributes_3_opens');
  var closes3 = document.getElementById('store_opening_hours_attributes_3_closes');
  var checkbox3 = document.getElementById('store_opening_hours_attributes_3__destroy');

  if (opens3 && closes3) {
    opens3.addEventListener('change', (event) => {
      if (opens3.value !== "" && closes3.value !== "") {
        checkbox3.value = 0
      };
      if (opens3.value === "" || closes3.value === "") {
        checkbox3.value = 1
      };
    });
    closes3.addEventListener('change', (event) => {
      console.log("changed_closes")
      if (opens3.value !== "" && closes3.value !== "") {
        checkbox3.value = 0
      };
      if (opens3.value === "" || closes3.value === "") {
        checkbox3.value = 1
      };
    });
  };

  var opens4 = document.getElementById('store_opening_hours_attributes_4_opens');
  var closes4 = document.getElementById('store_opening_hours_attributes_4_closes');
  var checkbox4 = document.getElementById('store_opening_hours_attributes_4__destroy');

  if (opens4 && closes4) {
    opens4.addEventListener('change', (event) => {
      if (opens4.value !== "" && closes4.value !== "") {
        checkbox4.value = 0
      };
      if (opens4.value === "" || closes4.value === "") {
        checkbox4.value = 1
      };
    });
    closes4.addEventListener('change', (event) => {
      console.log("changed_closes")
      if (opens4.value !== "" && closes4.value !== "") {
        checkbox4.value = 0
      };
      if (opens4.value === "" || closes4.value === "") {
        checkbox4.value = 1
      };
    });
  };

  var opens5 = document.getElementById('store_opening_hours_attributes_5_opens');
  var closes5 = document.getElementById('store_opening_hours_attributes_5_closes');
  var checkbox5 = document.getElementById('store_opening_hours_attributes_5__destroy');

  if (opens5 && closes5) {
    opens5.addEventListener('change', (event) => {
      if (opens5.value !== "" && closes5.value !== "") {
        checkbox5.value = 0
      };
      if (opens5.value === "" || closes5.value === "") {
        checkbox5.value = 1
      };
    });
    closes5.addEventListener('change', (event) => {
      console.log("changed_closes")
      if (opens5.value !== "" && closes5.value !== "") {
        checkbox5.value = 0
      };
      if (opens5.value === "" || closes5.value === "") {
        checkbox5.value = 1
      };
    });
  };

  var opens6 = document.getElementById('store_opening_hours_attributes_6_opens');
  var closes6 = document.getElementById('store_opening_hours_attributes_6_closes');
  var checkbox6 = document.getElementById('store_opening_hours_attributes_6__destroy');

  if (opens6 && closes6) {
    opens6.addEventListener('change', (event) => {
      if (opens6.value !== "" && closes6.value !== "") {
        checkbox6.value = 0
      };
      if (opens6.value === "" || closes6.value === "") {
        checkbox6.value = 1
      };
    });
    closes6.addEventListener('change', (event) => {
      console.log("changed_closes")
      if (opens6.value !== "" && closes6.value !== "") {
        checkbox6.value = 0
      };
      if (opens6.value === "" || closes6.value === "") {
        checkbox6.value = 1
      };
    });
  };
};
