export const hoverPin = () => {

  var storeCards = document.getElementsByClassName('search-results-store-card');

  Array.from(storeCards).forEach((card) => {
    card.onmouseover = function(){
      let marker = document.getElementById(`store-${card.id}`);
      marker.style.backgroundImage = `url('https://res.cloudinary.com/dxlqd3rxd/image/upload/v1648813881/orderize_icon-svg_2_hs0kbf.png')`
      marker.style.width = "90px"
      marker.style.height = "90px"
    };
    card.onmouseleave = function(){
      let marker = document.getElementById(`store-${card.id}`);
      marker.style.backgroundImage = `url('https://res.cloudinary.com/dxlqd3rxd/image/upload/v1647530727/orderize_icon-svg_lguk8o.png')`
      marker.style.width = "70px"
      marker.style.height = "70px"
    };
  });
};
