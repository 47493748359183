export const chooseProduct = () => {
  const b2cCheck = document.getElementById("b2c-check");
  const b2bCheck = document.getElementById("b2b-check");
  const b2cBtn = document.getElementById("b2c-button");
  const b2bBtn = document.getElementById("b2b-button");
  const b2cValue = document.getElementById("new_store_store_product_b2c");
  const b2bValue = document.getElementById("new_store_store_product_b2b");
  const b2cValueServices = document.getElementById("b2c");
  const b2bValueServices = document.getElementById("b2b");

  if (b2cBtn) {
    b2cBtn.addEventListener('click', () => {
      if (b2cCheck.classList.contains('active')) {
        b2cCheck.classList.remove('active')
        if (b2cValue) {
          b2cValue.value = false ;
        } else {
          b2cValueServices.value = false ;
        }
      } else {
        b2cCheck.classList.add('active')
        if (b2cValue) {
          b2cValue.value = true ;
        } else {
          b2cValueServices.value = true ;
        }
      };
    });
  }

  if (b2bBtn) {
    b2bBtn.addEventListener('click', () => {
      if (b2bCheck.classList.contains('active')) {
        b2bCheck.classList.remove('active')
        if (b2bValue) {
          b2bValue.value = false ;
        } else {
          b2bValueServices.value = false ;
        }
      } else {
        b2bCheck.classList.add('active')
        if (b2bValue) {
          b2bValue.value = true ;
        } else {
          b2bValueServices.value = true ;
        }
      };
    });
  }
};
