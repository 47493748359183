export const showDeliverySettings = () => {
  var flatFee = document.getElementById('store_default_delivery_pricing_option_flat_rate')
  var distanceBased = document.getElementById('store_default_delivery_pricing_option_distance_based')
  var flatFeeFields = document.getElementById('flat-fee-fields')
  var distanceBasedFields = document.getElementById('distance-based-fields')

  if ((flatFee) && (distanceBased)) {
    if (flatFee.checked) {
      flatFeeFields.style.display = "block";
      distanceBasedFields.style.display = "none";
    }
    else if (distanceBased.checked) {
      flatFeeFields.style.display = "none";
      distanceBasedFields.style.display = "block";
    }
  };

  const weekdayPills = document.querySelectorAll('.weekday-pill');

  weekdayPills.forEach(pill => {
      pill.addEventListener('click', function() {
          console.log('Pill clicked'); // Add this line
          this.classList.toggle('active');
      });
  });
};


export const updateDeliveryInfoWeekdays = () => {
  const weekdayPills = document.querySelectorAll('.weekday-pill');

  weekdayPills.forEach(function(pill) {
    pill.addEventListener('click', function() {
      this.classList.toggle('active');
      const weekday = this.dataset.weekday;
      const deliveryAddressId = this.dataset.deliveryAddressId;
      const hiddenInput = document.getElementById(`selected-weekday-${deliveryAddressId}-${weekday}`);

      // Check if the button is active
      if (this.classList.contains('active')) {
        hiddenInput.value = weekday; // If active, set the value to the weekday
      } else {
        hiddenInput.value = ''; // If inactive, set the value to empty string
      }
    });
  });
};
