export const initFlatpickr = () => {

  // range picker
  const dateRangeInput = document.getElementById('date_range');
  const startDateInput = document.getElementById('start_date');
  const endDateInput = document.getElementById('end_date');

  if (dateRangeInput) {
    if (startDateInput && endDateInput) {
      flatpickr(dateRangeInput, {
        mode: "range",
        minDate: "today",
        dateFormat: "Y-m-d",
        defaultDate: [startDateInput.value, endDateInput.value]
      });
    } else {
      flatpickr(dateRangeInput, {
        mode: "range",
        minDate: "today",
        dateFormat: "Y-m-d"
      });
    }
  };

  // pick up time b2b confirm order
  const startPickUpTimeInput = document.getElementById('customer_pick_up_start');
  const endPickUpTimeInput = document.getElementById('customer_pick_up_end');
  const minPickUpTime = document.getElementById('customer_pick_up_min_time');
  const maxPickUpTime = document.getElementById('customer_pick_up_max_time');

  if (startPickUpTimeInput && endPickUpTimeInput) {
    flatpickr(startPickUpTimeInput, {
      enableTime: true,
      noCalendar: true,
      dateFormat: "H:i",
      time_24hr: true,
      minTime: minPickUpTime.value,
      maxTime: maxPickUpTime.value,
      defaultDate: minPickUpTime.value
    });
    flatpickr(endPickUpTimeInput, {
      enableTime: true,
      noCalendar: true,
      dateFormat: "H:i",
      time_24hr: true,
      minTime: minPickUpTime.value,
      maxTime: maxPickUpTime.value,
      defaultDate: maxPickUpTime.value
    });
  }

  // delivery time b2b choose delivery
  const startDeliveryTimeInput = document.getElementById('drop_off_start');
  const endDeliveryTimeInput = document.getElementById('drop_off_end');
  const minDeliveryTime = document.getElementById('customer_delivery_min_time');
  const maxDeliveryTime = document.getElementById('customer_delivery_max_time');

  if (startDeliveryTimeInput && endDeliveryTimeInput && minDeliveryTime && maxDeliveryTime) {
    flatpickr(startDeliveryTimeInput, {
      enableTime: true,
      noCalendar: true,
      dateFormat: "H:i",
      time_24hr: true,
      minTime: minDeliveryTime.value,
      maxTime: maxDeliveryTime.value,
      defaultDate: minDeliveryTime.value
    });
    flatpickr(endDeliveryTimeInput, {
      enableTime: true,
      noCalendar: true,
      dateFormat: "H:i",
      time_24hr: true,
      minTime: minDeliveryTime.value,
      maxTime: maxDeliveryTime.value,
      defaultDate: maxDeliveryTime.value
    });
  }

  // pick up time product edit
  const startPickUpTimeLimitInput = document.getElementById('product_pick_up_time_limit_start');
  const endPickUpTimeLimitInput = document.getElementById('product_pick_up_time_limit_end');
  const startPickUpTimeLimit = document.getElementById('pick_up_time_limit_start_value');
  const endPickUpTimeLimit = document.getElementById('pick_up_time_limit_end_value');

  if (startPickUpTimeLimitInput && endPickUpTimeLimitInput) {
    flatpickr(startPickUpTimeLimitInput, {
      enableTime: true,
      noCalendar: true,
      dateFormat: "H:i",
      time_24hr: true,
      defaultDate: startPickUpTimeLimit.value
    });
    flatpickr(endPickUpTimeLimitInput, {
      enableTime: true,
      noCalendar: true,
      dateFormat: "H:i",
      time_24hr: true,
      defaultDate: endPickUpTimeLimit.value
    });
  }

  // delivery time product edit
  const startDeliveryTimeLimitInput = document.getElementById('product_delivery_time_limit_start');
  const endDeliveryTimeLimitInput = document.getElementById('product_delivery_time_limit_end');
  const startDeliveryTimeLimit = document.getElementById('delivery_time_limit_start_value');
  const endDeliveryTimeLimit = document.getElementById('delivery_time_limit_end_value');

  if (startDeliveryTimeLimitInput && endDeliveryTimeLimitInput) {
    flatpickr(startDeliveryTimeLimitInput, {
      enableTime: true,
      noCalendar: true,
      dateFormat: "H:i",
      time_24hr: true,
      defaultDate: startDeliveryTimeLimit.value
    });
    flatpickr(endDeliveryTimeLimitInput, {
      enableTime: true,
      noCalendar: true,
      dateFormat: "H:i",
      time_24hr: true,
      defaultDate: endDeliveryTimeLimit.value
    });
  }

  // weekly pick up & delivery time weekly b2b confirm order
  const weeklyShoppingCartsCollection = [...document.getElementsByClassName("weekly-checkout-order-overview")].map(el => el.id );

  Array.from(weeklyShoppingCartsCollection).forEach(function(sc) {
    var id = sc
    var weeklyStartPickUpTimeLimit = document.getElementById(`customer_pick_up_min_time${id}`);
    var weeklyEndPickUpTimeLimit = document.getElementById(`customer_pick_up_max_time${id}`);
    var weeklyStartPickUpTimeLimitInput = document.getElementById(`customer_pick_up_start${id}`);
    var weeklyEndPickUpTimeLimitInput = document.getElementById(`customer_pick_up_end${id}`);

    if (weeklyStartPickUpTimeLimitInput && weeklyEndPickUpTimeLimitInput) {
      flatpickr(weeklyStartPickUpTimeLimitInput, {
        enableTime: true,
        noCalendar: true,
        dateFormat: "H:i",
        time_24hr: true,
        minTime: weeklyStartPickUpTimeLimit.value,
        maxTime: weeklyEndPickUpTimeLimit.value,
        defaultDate: weeklyStartPickUpTimeLimit.value
      });
      flatpickr(weeklyEndPickUpTimeLimitInput, {
        enableTime: true,
        noCalendar: true,
        dateFormat: "H:i",
        time_24hr: true,
        minTime: weeklyStartPickUpTimeLimit.value,
        maxTime: weeklyEndPickUpTimeLimit.value,
        defaultDate: weeklyEndPickUpTimeLimit.value
      });
    };

    var weeklyStartDeliveryTimeLimit = document.getElementById(`customer_delivery_min_time${id}`);
    var weeklyEndDeliveryTimeLimit = document.getElementById(`customer_delivery_max_time${id}`);
    var weeklyStartDeliveryTimeLimitInput = document.getElementById(`customer_delivery_start${id}`);
    var weeklyEndDeliveryTimeLimitInput = document.getElementById(`customer_delivery_end${id}`);

    if (weeklyStartDeliveryTimeLimitInput && weeklyEndDeliveryTimeLimitInput) {
      flatpickr(weeklyStartDeliveryTimeLimitInput, {
        enableTime: true,
        noCalendar: true,
        dateFormat: "H:i",
        time_24hr: true,
        minTime: weeklyStartDeliveryTimeLimit.value,
        maxTime: weeklyEndDeliveryTimeLimit.value,
        defaultDate: weeklyStartDeliveryTimeLimit.value
      });
      flatpickr(weeklyEndDeliveryTimeLimitInput, {
        enableTime: true,
        noCalendar: true,
        dateFormat: "H:i",
        time_24hr: true,
        minTime: weeklyStartDeliveryTimeLimit.value,
        maxTime: weeklyEndDeliveryTimeLimit.value,
        defaultDate: weeklyEndDeliveryTimeLimit.value
      });
    };
  });

  // pick up time b2c confirm order
  const b2cStartTime = document.getElementById('b2c_customer_pick_up_time');
  const b2cMinPickUpTime = document.getElementById('b2c_customer_pick_up_min_time');
  const b2cMaxPickUpTime = document.getElementById('b2c_customer_pick_up_max_time');

  if (b2cStartTime) {
    flatpickr(b2cStartTime, {
      enableTime: true,
      noCalendar: true,
      dateFormat: "H:i",
      time_24hr: true,
      minTime: b2cMinPickUpTime.value,
      maxTime: b2cMaxPickUpTime.value,
      defaultDate: b2cMinPickUpTime.value
    })
  }
}
