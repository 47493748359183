export const keepTab = () => {
  $('#v-pills-tab a').click(function(e) {
    e.preventDefault();
    $(this).tab('show');
  });

  // store the currently selected tab in the hash value
  $("ul.nav-tabs > li > a").on("shown.bs.tab", function(e) {
    var id = $(e.target).attr("href").substr(1);
    window.location.hash = id;
  });

  // on load of the page: switch to the currently selected tab
  var hash = window.location.hash;


  if (hash !== "") {
    $('#v-pills-tab-tab a').removeClass("active");
    $("#pills-tabContent div.tab-pane").removeClass("active");
    $('#v-pills-tab a[href="' + hash + '"]').tab('show');
    $('#v-pills-tab-tab a[href="' + hash + '"]').addClass("active");
  };
};

export const keepSignUpTab = () => {
  $('#sign-up-pills-tab a').click(function(e) {
    e.preventDefault();
    $(this).tab('show');
  });

  // store the currently selected tab in the hash value
  $("ul.nav-tabs > li > a").on("shown.bs.tab", function(e) {
    var id = $(e.target).attr("href").substr(1);
    window.location.hash = id;
  });

  // on load of the page: switch to the currently selected tab
  var hash = window.location.hash;


  if (hash !== "") {
    $('#sign-up-pills-tab a').removeClass("active");
    $("#sign-up-pills-tabContent div.tab-pane").removeClass("active");
    $('#sign-up-pills-tab a[href="' + hash + '"]').tab('show');
    $('#sign-up-pills-tab a[href="' + hash + '"]').addClass("active");
  };
};

export const keepDashboardTab = () => {
  $('#pills-dashboard-tab a').click(function(e) {
    e.preventDefault();
    $(this).tab('show');
  });

  // store the currently selected tab in the hash value
  $("ul.nav-pills > li > a").on("shown.bs.tab", function(e) {
    var id = $(e.target).attr("href").substr(1);
    window.location.hash = id;
  });

  // on load of the page: switch to the currently selected tab
  var hash = window.location.hash;
  if (hash !== "") {
    $('#pills-dashboard-tab a').removeClass("active");
    $('#pills-dashboard-tab a').parent().removeClass("active");
    $("#pills-tabContent div.tab-pane").removeClass("active");
    $('#pills-dashboard-tab a[href="' + hash + '"]').tab('show');
    $('#pills-dashboard-tab a[href="' + hash + '"]').addClass("active");
    $('#pills-dashboard-tab a[href="' + hash + '"]').parent().addClass("active");

    var upcomingB2c = document.getElementById("upcoming-b2c");
    var upcomingB2b = document.getElementById("upcoming-b2b");
    var upcomingArrowUp = document.getElementById('upcoming-arrow-up');
    var upcomingArrowDown = document.getElementById('upcoming-arrow-down');
    var pastB2c = document.getElementById("past-b2c");
    var pastB2b = document.getElementById("past-b2b");
    var pastArrowUp = document.getElementById('past-arrow-up');
    var pastArrowDown = document.getElementById('past-arrow-down');
    var refundsB2c = document.getElementById("refunds-b2c");
    var refundsB2b = document.getElementById("refunds-b2b");
    var refundsRequests = document.getElementById("requested-refunds");
    var refundsArrowUp = document.getElementById('refunds-arrow-up');
    var refundsArrowDown = document.getElementById('refunds-arrow-down');

    if ((upcomingB2c) && (upcomingB2b)) {
      if (upcomingB2c.classList.contains('active') || upcomingB2b.classList.contains('active')) {
        upcomingB2c.style.display = "block";
        upcomingB2b.style.display = "block";
        upcomingArrowUp.style.display = "block";
        upcomingArrowDown.style.display = "none";
      }
    }
    if ((pastB2c) && (pastB2b)) {
      if (pastB2c.classList.contains('active') || pastB2b.classList.contains('active')) {
        pastB2c.style.display = "block";
        pastB2b.style.display = "block";
        pastArrowUp.style.display = "block";
        pastArrowDown.style.display = "none";
      }
    }
    if ((refundsB2c) && (refundsB2b) && (refundsRequests)) {
      if (refundsB2c.classList.contains('active') || refundsB2b.classList.contains('active') || refundsRequests.classList.contains('active')) {
        refundsB2c.style.display = "block";
        refundsB2b.style.display = "block";
        refundsRequests.style.display = "block";
        refundsArrowUp.style.display = "block";
        refundsArrowDown.style.display = "none";
      }
    }
    if ((refundsB2c) && (refundsB2b)) {
      if (refundsB2c.classList.contains('active') || refundsB2b.classList.contains('active')) {
        refundsB2c.style.display = "block";
        refundsB2b.style.display = "block";
        refundsArrowUp.style.display = "block";
        refundsArrowDown.style.display = "none";
      }
    }
    if ((refundsB2b) && (refundsRequests)) {
      if (refundsB2b.classList.contains('active') || refundsRequests.classList.contains('active')) {
        refundsB2b.style.display = "block";
        refundsRequests.style.display = "block";
        refundsArrowUp.style.display = "block";
        refundsArrowDown.style.display = "none";
      }
    }
  }
};

export const keepCustomerDashboardTab = () => {
  $('#pills-customer-dashboard-tab a').click(function(e) {
    e.preventDefault();
    $(this).tab('show');
  });

  $('ul.nav.nav-pills li a').click(function() {
    $(this).parent().addClass('active').siblings().removeClass('active');
  });

  // store the currently selected tab in the hash value
  $("ul.nav-pills > li > a").on("shown.bs.tab", function(e) {
    var id = $(e.target).attr("href").substr(1);
    window.location.hash = id;
  });

  // on load of the page: switch to the currently selected tab
  var hash = window.location.hash;
  if (hash !== "") {
    $('#pills-customer-dashboard-tab a').removeClass("active");
    $('#pills-customer-dashboard-tab a').parent().removeClass("active");
    $('#pills-customer-dashboard-tab a[href="' + hash + '"]').tab('show');
    $('#pills-customer-dashboard-tab a[href="' + hash + '"]').addClass("active");
    $('#pills-customer-dashboard-tab a[href="' + hash + '"]').parent().addClass("active");

    var upcomingB2c = document.getElementById("cust-upcoming-b2c");
    var upcomingB2b = document.getElementById("cust-upcoming-b2b");
    var upcomingArrowUp = document.getElementById('cust-upcoming-arrow-up');
    var upcomingArrowDown = document.getElementById('cust-upcoming-arrow-down');
    var pastB2c = document.getElementById("cust-past-b2c");
    var pastB2b = document.getElementById("cust-past-b2b");
    var pastArrowUp = document.getElementById('cust-past-arrow-up');
    var pastArrowDown = document.getElementById('cust-past-arrow-down');
    var refundsB2c = document.getElementById("cust-refunds-b2c");
    var refundsB2b = document.getElementById("cust-refunds-b2b");
    var refundsRequests = document.getElementById("cust-requested-refunds");
    var refundsArrowUp = document.getElementById('cust-refunds-arrow-up');
    var refundsArrowDown = document.getElementById('cust-refunds-arrow-down');

    if ((upcomingB2c) && (upcomingB2b)) {
      if (upcomingB2c.classList.contains('active') || upcomingB2b.classList.contains('active')) {
        upcomingB2c.style.display = "block";
        upcomingB2b.style.display = "block";
        upcomingArrowUp.style.display = "block";
        upcomingArrowDown.style.display = "none";
      }
    }
    if ((pastB2c) && (pastB2b)) {
      if (pastB2c.classList.contains('active') || pastB2b.classList.contains('active')) {
        pastB2c.style.display = "block";
        pastB2b.style.display = "block";
        pastArrowUp.style.display = "block";
        pastArrowDown.style.display = "none";
      }
    }
    if ((refundsB2c) && (refundsB2b) && (refundsRequests)) {
      if (refundsB2c.classList.contains('active') || refundsB2b.classList.contains('active') || refundsRequests.classList.contains('active')) {
        refundsB2c.style.display = "block";
        refundsB2b.style.display = "block";
        refundsRequests.style.display = "block";
        refundsArrowUp.style.display = "block";
        refundsArrowDown.style.display = "none";
      }
    }
    if ((refundsB2c) && (refundsB2b)) {
      if (refundsB2c.classList.contains('active') || refundsB2b.classList.contains('active')) {
        refundsB2c.style.display = "block";
        refundsB2b.style.display = "block";
        refundsArrowUp.style.display = "block";
        refundsArrowDown.style.display = "none";
      }
    }
    if ((refundsB2b) && (refundsRequests)) {
      if (refundsB2b.classList.contains('active') || refundsRequests.classList.contains('active')) {
        refundsB2b.style.display = "block";
        refundsRequests.style.display = "block";
        refundsArrowUp.style.display = "block";
        refundsArrowDown.style.display = "none";
      }
    }
  }
};

export const keepOfferingsTab = () => {
  $("ul.nav-pills > li > a").on("shown.bs.tab", function(e) {
    var id = $(e.target).attr("href").substr(1);
    window.location.hash = id;
  });

  // on load of the page: switch to the currently selected tab
  var hash = window.location.hash;

  if (hash) {
    var target = $(`${hash}`);
    var targetLink = $(`#offeringsTab a[href="${hash}"]`);
    var parentPanes=[];
    target.parentsUntil("body").each(function(){
      var $dom=$(this);
      if($dom.hasClass("tab-pane")&&$dom.attr("id")){
        parentPanes.push($dom.attr("id"));
      }
    });
    parentPanes.reduceRight(function(pre,parentId){
      $(`#offeringsTab a[href="#${parentId}"]`).tab('show');
    },"");
    targetLink.tab('show');
  }
};

export const keepSettingsTab = () => {
  $('#pills-settings-tab a').click(function(e) {
    e.preventDefault();
    $(this).tab('show');
  });

  // store the currently selected tab in the hash value
  $("ul.nav-pills > li > a").on("shown.bs.tab", function(e) {
    var id = $(e.target).attr("href").substr(1);
    window.location.hash = id;
  });

  // on load of the page: switch to the currently selected tab
  var hash = window.location.hash;
  if (hash !== "") {
    $('#pills-settings-tab a').removeClass("active");
    $('#pills-settings-tab a').parent().removeClass("active");
    $('#pills-settings-tab a[href="' + hash + '"]').tab('show');
    $('#pills-settings-tab a[href="' + hash + '"]').addClass("active");
    $('#pills-settings-tab a[href="' + hash + '"]').parent().addClass("active");

    var storeInfos = document.getElementById("store-infos");
    var bankDetails = document.getElementById("bank-details");
    var vatRate = document.getElementById("vat-rate");
    var photos = document.getElementById("photos");
    var agbPp = document.getElementById("agb-pp");
    var openingHours = document.getElementById("opening-hours");
    var employees = document.getElementById("employees");
    var api = document.getElementById("api");
    var settingsStoreArrowUp = document.getElementById('settings-store-arrow-up');
    var settingsStoreArrowDown = document.getElementById('settings-store-arrow-down');
    var settingsB2cCustomers = document.getElementById("settings-b2c-customers");
    var settingsB2bCustomers = document.getElementById("settings-b2b-customers");
    var settingsPreordersArrowUp = document.getElementById('settings-preorders-arrow-up');
    var settingsPreordersArrowDown = document.getElementById('settings-preorders-arrow-down');

    if ((storeInfos) && (bankDetails) && (vatRate) && (photos) && (agbPp) && (openingHours) && (employees) && (api)) {
      if (storeInfos.classList.contains('active') || bankDetails.classList.contains('active') || vatRate.classList.contains('active') || photos.classList.contains('active') || agbPp.classList.contains('active') || openingHours.classList.contains('active') || employees.classList.contains('active') || api.classList.contains('active')) {
        storeInfos.style.display = "block";
        bankDetails.style.display = "block";
        vatRate.style.display = "block";
        photos.style.display = "block";
        agbPp.style.display = "block";
        openingHours.style.display = "block";
        employees.style.display = "block";
        api.style.display = "block";
        settingsStoreArrowUp.style.display = "block";
        settingsStoreArrowDown.style.display = "none";
      }
    }
    if ((storeInfos) && (photos) && (openingHours) && (employees) && (vatRate == null) && (agbPp == null) && (bankDetails == null) && (api == null)) {
      if (storeInfos.classList.contains('active') || photos.classList.contains('active') || openingHours.classList.contains('active') || employees.classList.contains('active')) {
        storeInfos.style.display = "block";
        photos.style.display = "block";
        openingHours.style.display = "block";
        employees.style.display = "block";
        settingsStoreArrowUp.style.display = "block";
        settingsStoreArrowDown.style.display = "none";
      }
    }
    if ((settingsB2cCustomers) && (settingsB2bCustomers)) {
      if (settingsB2cCustomers.classList.contains('active') || settingsB2bCustomers.classList.contains('active')) {
        settingsB2cCustomers.style.display = "block";
        settingsB2bCustomers.style.display = "block";
        settingsPreordersArrowUp.style.display = "block";
        settingsPreordersArrowDown.style.display = "none";
      }
    }
    if (settingsB2cCustomers && settingsB2bCustomers == null) {
      if (settingsB2cCustomers.classList.contains('active')) {
        settingsB2cCustomers.style.display = "block";
        settingsPreordersArrowUp.style.display = "block";
        settingsPreordersArrowDown.style.display = "none";
      }
    };

    if (settingsB2bCustomers && settingsB2cCustomers == null) {
      if (settingsB2bCustomers.classList.contains('active')) {
        settingsB2bCustomers.style.display = "block";
        settingsPreordersArrowUp.style.display = "block";
        settingsPreordersArrowDown.style.display = "none";
      }
    };
  }
};

export const keepProductSettingsTab = () => {
  $('#pills-product-settings-tab a').click(function(e) {
    e.preventDefault();
    $(this).tab('show');
  });

  // store the currently selected tab in the hash value
  $("ul.nav-pills > li > a").on("shown.bs.tab", function(e) {
    var id = $(e.target).attr("href").substr(1);
    window.location.hash = id;
  });

  // on load of the page: switch to the currently selected tab
  var hash = window.location.hash;
  if (hash !== "") {
    $('#pills-product-settings-tab a').removeClass("active");
    $('#pills-product-settings-tab a').parent().removeClass("active");
    $('#pills-product-settings-tab a[href="' + hash + '"]').tab('show');
    $('#pills-product-settings-tab a[href="' + hash + '"]').addClass("active");
    $('#pills-product-settings-tab a[href="' + hash + '"]').parent().addClass("active");
  }
};

export const keepPlanningTab = () => {
  $('#pills-planning-tab a').click(function(e) {
    e.preventDefault();
    $(this).tab('show');
  });

  // store the currently selected tab in the hash value
  $("ul.nav-pills > li > a").on("shown.bs.tab", function(e) {
    var id = $(e.target).attr("href").substr(1);
    window.location.hash = id;
  });

  // on load of the page: switch to the currently selected tab
  var hash = window.location.hash;
  if (hash !== "") {
    $('#pills-planning-tab a').removeClass("active");
    $('#pills-planning-tab a').parent().removeClass("active");
    $('#pills-planning-tab a[href="' + hash + '"]').tab('show');
    $('#pills-planning-tab a[href="' + hash + '"]').addClass("active");
    $('#pills-planning-tab a[href="' + hash + '"]').parent().addClass("active");
  }
};


export const keepCustomerSettingsTab = () => {
  $('#pills-customer-profile-settings-tab a').click(function(e) {
    e.preventDefault();
    $(this).tab('show');
  });

  // store the currently selected tab in the hash value
  $("ul.nav-pills > li > a").on("shown.bs.tab", function(e) {
    var id = $(e.target).attr("href").substr(1);
    window.location.hash = id;
  });

  // on load of the page: switch to the currently selected tab
  var hash = window.location.hash;
  if (hash !== "") {
    $('#pills-customer-profile-settings-tab a').removeClass("active");
    $('#pills-customer-profile-settings-tab a').parent().removeClass("active");
    $('#pills-customer-profile-settings-tab a[href="' + hash + '"]').tab('show');
    $('#pills-customer-profile-settings-tab a[href="' + hash + '"]').addClass("active");
    $('#pills-customer-profile-settings-tab a[href="' + hash + '"]').parent().addClass("active");
  };
};


