export const showStripeContainer = () => {

  var stripeButton = document.getElementById('stripe-connection-container-toggle');
  var stripeContainer = document.getElementById('stripe-connection-container');

  if (stripeButton) {
    stripeButton.onclick = function(){
      stripeContainer.style.display = "block";
      stripeButton.style.display = "none";
    };
  };
};
