export const forwardWeeklyReminderMail = () => {
  if (document.getElementById('add_forward_weekly_reminder_mail')) {
    document.getElementById('add_forward_weekly_reminder_mail').addEventListener('click', function(e) {
      e.preventDefault();
      let emailFieldsContainer = document.querySelector('.forward-weekly-reminder-mail-fields-container');
      let emailField = emailFieldsContainer.querySelector('.forward-weekly-reminder-mail-field');
      let clone = emailField.cloneNode(true);

      // Generate a new unique index
      let newIndex = Date.now();

      // Update all input fields in the cloned email field
      clone.querySelectorAll('input').forEach(function(input) {
          input.name = input.name.replace(/\d+/g, newIndex);  // Replace number in name with the new unique index
          input.id = input.id.replace(/\d+/g, newIndex);  // Do the same for the id attribute
      });

      // Clear the value for the new email field
      let newEmailInput = clone.querySelector('.email-input');
      newEmailInput.style.textDecoration = 'none';
      newEmailInput.value = '';

      // Reset the hidden destroy input
      let hiddenDestroyInput = clone.querySelector('input[name*="_destroy"]');
      hiddenDestroyInput.value = "false";

      // Attach the event listener to the new trash icon
      let newTrashIcon = clone.querySelector('.remove-forward-weekly-reminder-mail');
      newTrashIcon.addEventListener('click', handleTrashIconClick);

      emailFieldsContainer.appendChild(clone);
    });
  };

  // Function to handle the trash icon click event
  function handleTrashIconClick(e) {
    e.preventDefault();

    let emailFieldContainer = e.currentTarget.closest('.forward-weekly-reminder-mail-field');
    let emailField = emailFieldContainer.querySelector('.email-input');
    let hiddenDestroyInput = emailFieldContainer.querySelector('input[name*="_destroy"]');

    if (hiddenDestroyInput.value === "false") {
        emailField.classList.add('grayed-out');
        emailField.setAttribute('readonly', true); // Set the field as non-editable
        hiddenDestroyInput.value = "true";
    } else {
        emailField.classList.remove('grayed-out');
        emailField.removeAttribute('readonly'); // Make the field editable again
        hiddenDestroyInput.value = "false";
    }
  }

  // Attach the event listener to existing trash icons
  document.querySelectorAll('.remove-forward-weekly-reminder-mail').forEach(function(icon) {
      icon.addEventListener('click', handleTrashIconClick);
  });
};
