export const showWeeklyOrder = () => {

  var showMoreBtn = document.getElementById('weekly-order-show-more');
  var weeklyOrderFields = document.getElementById('weekly-order-selection-fields');
  var anchorLocation = window.location.hash

  if (weeklyOrderFields && showMoreBtn) {
    showMoreBtn.onclick = function(){
      weeklyOrderFields.style.display = "block";
      showMoreBtn.style.display = "none";
    };
    if (anchorLocation != '') {
      weeklyOrderFields.style.display = "block";
      showMoreBtn.style.display = "none";
    }
  };
};
