export const dropDownSolutionsNavbar = () => {
  var solutionsDropDownGroup = document.getElementById('navbar-solution');
  var solutionDropDownNavItem = document.getElementById('navbar-solutions-dropdown');
  var solutionsDropDownItems = document.getElementById('navbar-solutions-items');
  var navBar = document.getElementById('navbar');
  var priceNavLink = document.getElementById('nav-prices-link');
  var registerBtn = document.getElementById('home-navbar-link-register');
  var loginBtn = document.getElementById('home-navbar-link-log-in');
  var homeNavBar = document.getElementById('home-navbar')
  // var arrowUp = document.getElementById('solutions-up');
  // var arrowDown = document.getElementById('solutions-down');

  if (solutionsDropDownGroup) {
    solutionDropDownNavItem.addEventListener('mouseover', () => {
      solutionsDropDownItems.style.display = "block";
      solutionsDropDownGroup.classList.add('active-nav-item');
      // navBar.classList.add('expanded-nav');
      if ((homeNavBar) && (window.pageYOffset === 0)) {
        navBar.classList.remove('transparant');
      };
    });
    solutionsDropDownItems.addEventListener('mouseover', () => {
      solutionsDropDownItems.style.display = "block";
      // navBar.classList.add('expanded-nav');
      solutionsDropDownGroup.classList.add('active-nav-item');
      if ((homeNavBar) && (window.pageYOffset === 0)) {
        navBar.classList.remove('transparant');
      };
    });
    solutionsDropDownItems.addEventListener('mouseout', () => {
      solutionsDropDownItems.style.display = "none";
      // navBar.classList.remove('expanded-nav');
      solutionsDropDownGroup.classList.remove('active-nav-item');
      if ((homeNavBar) && (window.pageYOffset === 0)) {
        navBar.classList.add('transparant');
      };
    });
    priceNavLink.addEventListener('mouseover', () => {
      solutionsDropDownItems.style.display = "none";
      solutionsDropDownGroup.classList.remove('active-nav-item');
      // navBar.classList.remove('expanded-nav');
      if ((homeNavBar) && (window.pageYOffset === 0)) {
        navBar.classList.add('transparant');
      };
    });
    registerBtn.addEventListener('mouseover', () => {
      solutionsDropDownItems.style.display = "none";
      solutionsDropDownGroup.classList.remove('active-nav-item');
      // navBar.classList.remove('expanded-nav');
      if ((homeNavBar) && (window.pageYOffset === 0)) {
        navBar.classList.add('transparant');
      };
    });
    loginBtn.addEventListener('mouseover', () => {
      solutionsDropDownItems.style.display = "none";
      solutionsDropDownGroup.classList.remove('active-nav-item');
      // navBar.classList.remove('expanded-nav');
      if ((homeNavBar) && (window.pageYOffset === 0)) {
        navBar.classList.add('transparant');
      };
    });
  };
};

export const mobileNavBarsExpandable = () => {
  const navBar = document.getElementById('navbar');
  const bars = document.getElementById('nav-mobile-bars');
  const barsContent = document.getElementById('mobile-bars-content');
  const navXIcon = document.getElementById('mobile-nav-x');
  const mobileSolutionDropdown = document.getElementById('mobile-navbar-solutions-dropdown');
  const mobileSolutionsItems = document.getElementById('navbar-mobile-solutions-items');
  const mobileLocationsDropdown = document.getElementById('mobile-navbar-locations-dropdown');
  const mobileLocationsItems = document.getElementById('navbar-mobile-locations-items');
  var homeNavBar = document.getElementById('home-navbar')

  if (bars) {
    bars.addEventListener('click', () => {
      if (barsContent.style.display === "block") {
        barsContent.style.display = "none";
        // navBar.classList.remove('expanded-nav');
        if ((homeNavBar) && (window.pageYOffset === 0)) {
          navBar.classList.add('transparant');
        };
      } else {
        barsContent.style.display = "block";
        // navBar.classList.add('expanded-nav');
        if ((homeNavBar) && (window.pageYOffset === 0)) {
          navBar.classList.remove('transparant');
        };
        navBar.addEventListener('mouseleave', () => {
          console.log("inside 2")
          barsContent.style.display = "none";
          if ((homeNavBar) && (window.pageYOffset === 0)) {
            navBar.classList.add('transparant');
          };
        });
      };
    });
    if (mobileSolutionDropdown) {
      mobileSolutionDropdown.addEventListener('touchstart', (e) => {
        e.preventDefault();
        if (mobileSolutionsItems.style.display === 'block') {
          mobileSolutionsItems.style.display = 'none';
          mobileSolutionDropdown.classList.remove('active-nav-item')
        } else {
          mobileSolutionsItems.style.display = 'block';
          mobileSolutionDropdown.classList.add('active-nav-item')
        };
      });
    };
    if (mobileLocationsDropdown) {
      mobileLocationsDropdown.addEventListener('touchstart', (e) => {
        e.preventDefault();
        if (mobileLocationsItems.style.display === 'block') {
          mobileLocationsItems.style.display = 'none';
          mobileLocationsDropdown.classList.remove('active-nav-item')
        } else {
          mobileLocationsItems.style.display = 'block';
          mobileLocationsDropdown.classList.add('active-nav-item')
        };
      });
    };
  };
};

export const locationsNavExpandable = () => {
  var navBar = document.getElementById('navbar');
  var homeNavBar = document.getElementById('home-navbar');
  var locationsGroup = document.getElementById('locations-dropdown-group');
  var locationsNavItem = document.getElementById('locations-dropdown');
  var locationsDropDownItems = document.getElementById('locations-group');
  var storeLink = document.getElementById('nav-store-link');
  var dashLink = document.getElementById('nav-dash-link');
  var planningLink = document.getElementById('nav-planning-link');

  if (locationsGroup) {
    locationsNavItem.addEventListener('mouseover', () => {
      locationsDropDownItems.style.display = "block";
      locationsGroup.classList.add('active-nav-item');
      // navBar.classList.add('expanded-nav');
      if ((homeNavBar) && (window.pageYOffset === 0)) {
        navBar.classList.remove('transparant');
      };
    });
    locationsDropDownItems.addEventListener('mouseover', () => {
      locationsDropDownItems.style.display = "block";
      // navBar.classList.add('expanded-nav');
      locationsGroup.classList.add('active-nav-item');
      if ((homeNavBar) && (window.pageYOffset === 0)) {
        navBar.classList.remove('transparant');
      };
    });
    locationsDropDownItems.addEventListener('mouseleave', () => {
      locationsDropDownItems.style.display = "none";
      // navBar.classList.remove('expanded-nav');
      locationsGroup.classList.remove('active-nav-item');
      if ((homeNavBar) && (window.pageYOffset === 0)) {
        navBar.classList.add('transparant');
      };
    });
    storeLink.addEventListener('mouseover', () => {
      locationsDropDownItems.style.display = "none";
      locationsGroup.classList.remove('active-nav-item');
      // navBar.classList.remove('expanded-nav');
      if ((homeNavBar) && (window.pageYOffset === 0)) {
        navBar.classList.add('transparant');
      };
    });
    if (dashLink) {
      dashLink.addEventListener('mouseover', () => {
        locationsDropDownItems.style.display = "none";
        locationsGroup.classList.remove('active-nav-item');
        // navBar.classList.remove('expanded-nav');
        if ((homeNavBar) && (window.pageYOffset === 0)) {
          navBar.classList.add('transparant');
        };
      });
    };
    if (planningLink) {
      planningLink.addEventListener('mouseover', () => {
        locationsDropDownItems.style.display = "none";
        locationsGroup.classList.remove('active-nav-item');
        // navBar.classList.remove('expanded-nav');
        if ((homeNavBar) && (window.pageYOffset === 0)) {
          navBar.classList.add('transparant');
        };
      });
    };
  };
};
