export const storeDescReadMore = () => {
  var storeDescReadMoreBtn = document.getElementById("desc-read-more-btn");
  var storeDescMoreText = document.getElementById("store-desc-more-text");
  var storeDescDots= document.getElementById("store-desc-dots");

  if ($('body').data('locale') == 'de') {
    var more = 'Mehr';
    var less = 'Weniger';
  };

  if ($('body').data('locale') == 'en') {
    var more = 'More';
    var less = 'Less';
  };

  if (storeDescReadMoreBtn) {
    storeDescReadMoreBtn.addEventListener("click", function() {
      if (storeDescMoreText.classList.contains('hide')) {
        storeDescMoreText.classList.remove("hide");
        storeDescDots.classList.add("hide");
        storeDescReadMoreBtn.innerHTML = `${less}`;
      } else {
        storeDescDots.classList.remove("hide");
        storeDescMoreText.classList.add("hide");
        storeDescReadMoreBtn.innerHTML = `${more}`;
      };
    });
  };
};
