export const keepStoreOwnerOfferingsTab = () => {
  $("ul.nav-tabs > li > a").on("shown.bs.tab", function(e) {
    var id = $(e.target).attr("href").substr(1);
    window.location.hash = id;
  });

  // on load of the page: switch to the currently selected tab
  var hash = window.location.hash;

  if (hash) {
    var target = $(`${hash}`);
    var targetLink = $(`#offeringsTab a[href="${hash}"]`);
    var parentPanes=[];
    target.parentsUntil("body").each(function(){
      var $dom=$(this);
      if($dom.hasClass("tab-pane")&&$dom.attr("id")){
        parentPanes.push($dom.attr("id"));
      }
    });
    parentPanes.reduceRight(function(pre,parentId){
      $(`#offeringsTab a[href="#${parentId}"]`).tab('show');
    },"");
    targetLink.tab('show');
  }
};

export const showOfferingEdit = () => {

  const editButtons = document.querySelectorAll('.edit-offering-btn');
  const formContainers = document.querySelectorAll('.edit-offering-form');
  const deleteButtons = document.querySelectorAll('.delete-offering-btn');

  editButtons.forEach(function(button, index) {
    button.addEventListener('click', function(event) {
      event.preventDefault();
      formContainers[index].style.display = 'block';
      button.style.display = 'none'; // Hide edit button
      deleteButtons[index].style.display = 'none'; // Hide delete button
    });
  });
};
