export const displayPill = () => {

  $('a[data-toggle="pill"]').on('show.bs.tab', function(e) {
    localStorage.setItem('activeTab', $(e.target).attr('href'));
    });
    var activeTab = localStorage.getItem('activeTab');
    if(activeTab){
        $('#pills-tab a[href="' + activeTab + '"]').tab('show');
    };
};

export const goToRefundRequests = () => {
  var moreBtn = document.getElementById("store-refund-requests-btn");

  if (moreBtn) {
    moreBtn.addEventListener("click", function() {
      console.log('clicked')
      $('#pills-dashboard-tab a').removeClass("active");
      $('#pills-dashboard-tab a').parent().removeClass("active");
      $("#pills-tabContent div.tab-pane").removeClass("active");
      $('#pills-dashboard-tab a[href="#pills-requested-refunds"]').tab('show');
      $('#pills-dashboard-tab a[href="#pills-requested-refunds"]').addClass("active");
      $('#pills-dashboard-tab a[href="#pills-requested-refunds"]').parent().addClass("active");
    });
  }
};
