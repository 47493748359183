export const selectPaymentMethod = () => {
  const payDirectly = document.getElementById("pay-directly");
  const payByInvoice = document.getElementById("pay-by-invoice");
  const paymentMethod = document.getElementById("payment_method");

  if (payDirectly) {
    payDirectly.addEventListener('click', () => {
      if (payDirectly.classList.contains('selected')) {
        if (paymentMethod.value = "pay-directly"){

        }
        else {
          paymentMethod.value = "pay-directly";
        }
      } else {
        payDirectly.classList.add('selected');
        if (payByInvoice && payByInvoice.classList.contains('selected')) {
          payByInvoice.classList.remove('selected')
        }
        paymentMethod.value = "pay-directly";
      };
    });
  };

  if (payByInvoice) {
    payByInvoice.addEventListener('click', () => {
      if (payByInvoice.classList.contains('selected')) {
        if (paymentMethod.value = "pay-by-invoice"){

        }
        else {
          paymentMethod.value = "pay-by-invoice";
        }
      } else {
        payByInvoice.classList.add('selected');
        if (payDirectly) {
          if (payDirectly.classList.contains('selected')) {
            payDirectly.classList.remove('selected')
          }
        }
        paymentMethod.value = "pay-by-invoice";
      };
    });
  };
};

export const selectBillingAddress = () => {
  const billingAddress = document.getElementById("billing_address_id");
  const newBillingAddressBtn = document.getElementById("show-billing-address-fields");
  const billingAddressFields = document.getElementById("billing-address-new");
  const existingBillingAddresses = document.getElementById("existing-billing-addresses");
  const baSelected = document.getElementById("ba_selected");
  const saveInfoCheckBox = document.getElementById("save_info");
  const inputsBillingAddress = document.getElementsByClassName('required-input-ba');


  document.querySelectorAll(".choose-billing-address").forEach(button =>
    button.addEventListener('click', () => {
      billingAddress.value = button.id
      if (button.classList.contains('billing-address-selected')){

      }
      else {
        if (document.querySelector('.billing-address-selected')) {
          let btnSelectedId = document.querySelector('.billing-address-selected').id.split("-")[2]
          document.getElementById(btnSelectedId).style.display="block"
          document.querySelector('.billing-address-selected').classList.remove('billing-address-selected')
        }
        document.getElementById(`billing-address-${button.id}`).classList.add('billing-address-selected')
        button.style.display="none"
      }
    })
  );

  if (newBillingAddressBtn) {
    newBillingAddressBtn.addEventListener('click', () => {
      Array.from(inputsBillingAddress).forEach(input =>
        input.setAttribute("required", "")
      )
      billingAddressFields.style.display="block"
      existingBillingAddresses.style.display="none"
      newBillingAddressBtn.style.display="none"
      billingAddress.value = ""
      baSelected.value = "true"
    });
  };

  if ((existingBillingAddresses === null) && (billingAddressFields)) {
    Array.from(inputsBillingAddress).forEach(input =>
      input.setAttribute("required", "")
    )
    baSelected.value = "true";
    saveInfoCheckBox.checked = true;
  };
};


export const selectDeliveryMethod = () => {
  const delivery = document.getElementById("delivery-option");
  const pickUp = document.getElementById("pick-up-option");
  const deliveryMethod = document.getElementById("delivery_method");
  const deliveryAddressContainer = document.getElementById("delivery-address-container");
  const inputsDropOff = document.getElementsByClassName('required-input-do');
  const inputsDeliveryAddress = document.getElementsByClassName('required-input-da');


  if (delivery) {
    delivery.addEventListener('click', () => {
      if (delivery.classList.contains('selected')) {
        deliveryAddressContainer.style.display = "block";
        Array.from(inputsDropOff).forEach(input =>
          input.setAttribute("required", "")
        )
        if (deliveryMethod.value = "delivery-option"){

        }
        else {
          deliveryMethod.value = "delivery-option";
        }
      } else {
        Array.from(inputsDropOff).forEach(input =>
          input.setAttribute("required", "")
        )
        delivery.classList.add('selected');
        if (pickUp && pickUp.classList.contains('selected')) {
          pickUp.classList.remove('selected')
        }
        deliveryMethod.value = "delivery-option";
        deliveryAddressContainer.style.display = "block";
      };
    });
  };

  if (pickUp) {
    pickUp.addEventListener('click', () => {
      if (pickUp.classList.contains('selected')) {
        deliveryAddressContainer.style.display = "none";
        Array.from(inputsDropOff).forEach(input =>
          input.removeAttribute("required")
        )
        if (deliveryMethod.value = "pick-up-option"){

        }
        else {
          deliveryMethod.value = "pick-up-option";
        }
      } else {
        Array.from(inputsDropOff).forEach(input =>
          input.removeAttribute("required")
        )
        Array.from(inputsDeliveryAddress).forEach(input =>
          input.removeAttribute("required")
        )
        pickUp.classList.add('selected');
        if (delivery) {
          if (delivery.classList.contains('selected')) {
            delivery.classList.remove('selected')
          }
        }
        deliveryMethod.value = "pick-up-option";
        deliveryAddressContainer.style.display = "none";
      };
    });
  };

  if (delivery) {
    if (deliveryMethod.value === 'delivery-option') {
      if (delivery.classList.contains('selected')) {
        deliveryAddressContainer.style.display = "block";
        Array.from(inputsDropOff).forEach(input =>
          input.setAttribute("required", "")
        )
        if (deliveryMethod.value = "delivery-option"){

        }
        else {
          deliveryMethod.value = "delivery-option";
        }
      } else {
        delivery.classList.add('selected');
        Array.from(inputsDropOff).forEach(input =>
          input.setAttribute("required", "")
        )
        if (pickUp && pickUp.classList.contains('selected')) {
          pickUp.classList.remove('selected')
        }
        deliveryMethod.value = "delivery-option";
        deliveryAddressContainer.style.display = "block";
      };
    };


    if (deliveryMethod.value === 'pick-up-option') {
      if (pickUp.classList.contains('selected')) {
        deliveryAddressContainer.style.display = "none";
        Array.from(inputsDropOff).forEach(input =>
          input.removeAttribute("required")
        )
        Array.from(inputsDeliveryAddress).forEach(input =>
          input.removeAttribute("required")
        )
        if (deliveryMethod.value = "pick-up-option"){

        }
        else {
          deliveryMethod.value = "pick-up-option";
        }
      } else {
        pickUp.classList.add('selected');
        Array.from(inputsDropOff).forEach(input =>
          input.removeAttribute("required")
        )
        if (delivery) {
          if (delivery.classList.contains('selected')) {
            delivery.classList.remove('selected')
          }
        }
        deliveryMethod.value = "pick-up-option";
        deliveryAddressContainer.style.display = "none";
      };
    };
  };
};


export const selectDeliveryAddress = () => {
  const deliveryAddress = document.getElementById("delivery_address_id");
  const newdeliveryAddressBtn = document.getElementById("show-delivery-address-fields");
  const deliveryAddressFields = document.getElementById("delivery-address-new");
  const existingdeliveryAddresses = document.getElementById("existing-delivery-addresses");
  const daSelected = document.getElementById("da_selected");
  const inputsDeliveryAddress = document.getElementsByClassName('required-input-da');

  document.querySelectorAll(".choose-delivery-address").forEach(button =>
    button.addEventListener('click', () => {
      deliveryAddress.value = button.id
      if (button.classList.contains('delivery-address-selected')){

      }
      else {
        if (document.querySelector('.delivery-address-selected')) {
          let btnSelectedId = document.querySelector('.delivery-address-selected').id.split("-")[2]
          document.getElementById(btnSelectedId).style.display="block"
          document.querySelector('.delivery-address-selected').classList.remove('delivery-address-selected')
        }
        document.getElementById(`delivery-address-${button.id}`).classList.add('delivery-address-selected')
        button.style.display="none"
      }
    })
  );

  if (newdeliveryAddressBtn) {
    newdeliveryAddressBtn.addEventListener('click', () => {
      Array.from(inputsDeliveryAddress).forEach(input =>
        input.setAttribute("required", "")
      )
      deliveryAddressFields.style.display="block"
      existingdeliveryAddresses.style.display="none"
      newdeliveryAddressBtn.style.display="none"
      deliveryAddress.value = ""
      daSelected.value = "true"
    });
  };

  if ((existingdeliveryAddresses === null) && (deliveryAddressFields)) {
    daSelected.value = "true";
    Array.from(inputsDeliveryAddress).forEach(input =>
      input.setAttribute("required", "")
    )
  };
};
