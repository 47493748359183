export const displayNewBillingAddressForm = () => {
  const showNewBillingAddressFormBtn = document.getElementById("show-billing-address-fields-settings");
  const showNewBillingAddressForm = document.getElementById("billing-address-new-settings");
  const existingBillingAddresses = document.getElementById("settings-existing-billing-addresses");

  if (showNewBillingAddressFormBtn) {
    showNewBillingAddressFormBtn.addEventListener('click', () => {
      showNewBillingAddressForm.style.display="block";
      // existingBillingAddresses.style.display="none"
      showNewBillingAddressFormBtn.style.display="none";
      existingBillingAddresses.style.display="none";
    });
  };
};

export const displayNewDeliveryAddressForm = () => {
  const showNewDeliveryAddressFormBtn = document.getElementById("show-delivery-address-fields-settings");
  const showNewDeliveryAddressForm = document.getElementById("delivery-address-new-settings");
  const existingDeliveryAddresses = document.getElementById("settings-existing-delivery-addresses");

  if (showNewDeliveryAddressFormBtn) {
    showNewDeliveryAddressFormBtn.addEventListener('click', () => {
      showNewDeliveryAddressForm.style.display="block";
      // existingDeliveryAddresses.style.display="none"
      showNewDeliveryAddressFormBtn.style.display="none";
      existingDeliveryAddresses.style.display="none";
    });
  };
};
