export const collapsTableRows = () => {
  var b2bPricingTable = document.getElementById('b2b-pricing-table');
  var salesMore = document.getElementById("sales-more");
  var salesLess = document.getElementById("sales-less");
  var hiddenSalesGroup = document.getElementsByClassName("salesgroup");
  var operationsMore = document.getElementById("operations-more");
  var operationsLess = document.getElementById("operations-less");
  var hiddenOperationsGroup = document.getElementsByClassName("op-group");


  if (b2bPricingTable) {
    salesMore.addEventListener('click', () => {
      if (salesMore.style.display === '') {
        salesMore.classList.add('d-none');
        salesLess.classList.remove('d-none');
        for (let item of hiddenSalesGroup) {
          item.classList.remove('d-none');
        };
      };
    });

    salesLess.addEventListener('click', () => {
      if (salesLess.style.display === '') {
        salesLess.classList.add('d-none');
        salesMore.classList.remove('d-none');
        for (let item of hiddenSalesGroup) {
          item.classList.add('d-none');
        };
      };
    });

    operationsMore.addEventListener('click', () => {
      if (operationsMore.style.display === '') {
        operationsMore.classList.add('d-none');
        operationsLess.classList.remove('d-none');
        for (let item of hiddenOperationsGroup) {
          item.classList.remove('d-none');
        };
      };
    });

    operationsLess.addEventListener('click', () => {
      if (operationsLess.style.display === '') {
        operationsLess.classList.add('d-none');
        operationsMore.classList.remove('d-none');
        for (let item of hiddenOperationsGroup) {
          item.classList.add('d-none');
        };
      };
    });
  };
};
