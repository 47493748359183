export const ageVerificationOverlay = () => {
  const overlay = document.getElementById('age-verification-overlay');
  if (!overlay) return;

  function confirmAgeForStore(storeId) {
    fetch(`/stores/${storeId}/confirm_age`, {
      method: 'POST',
      headers: {
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
        'Content-Type': 'application/json',
        'Accept': 'application/json',
      },
      credentials: 'include',
    })
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok.');
      }
      return response.json();
    })
    .then(data => {
      if (data.set_cookie) {
        setAgeVerificationCookie();
      }
      overlay.style.display = 'none';
    })
    .catch(error => console.error('Error:', error));
  }

  function setAgeVerificationCookie() {
    const date = new Date();
    date.setTime(date.getTime() + (30*24*60*60*1000)); // 30 days in milliseconds
    const expires = "; expires=" + date.toUTCString();
    document.cookie = "ageVerified=true" + expires + "; path=/";
  }

  function getAgeVerificationCookie() {
    const nameEQ = "ageVerified=";
    const ca = document.cookie.split(';');
    for(let i = 0; i < ca.length; i++) {
      let c = ca[i].trim();
      if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length) === "true";
    }
    return false;
  }

  // Check both the cookie and the data attribute for age verification.
  const ageVerifiedByCookie = getAgeVerificationCookie();
  // Convert the attribute string to a boolean.
  const ageVerifiedByServer = overlay.getAttribute('data-age-verified') === 'true';

  if (!ageVerifiedByCookie && !ageVerifiedByServer) {
    overlay.style.display = 'flex';
  } else {
    overlay.style.display = 'none';
  }

  const verifyAgeButton = document.getElementById('verify-age');
  if (verifyAgeButton) {
    verifyAgeButton.addEventListener('click', function() {
      const storeId = this.getAttribute('data-store-id');
      confirmAgeForStore(storeId);
    });
  }
}
