// for opening hours
// when unchecked (meaning closed on that day), turns row grey
const editor = document.querySelector(".opening-hour-editor")

const update = checkbox => {
  if (checkbox.checked) {
    console.log("update check")
    checkbox.classList.remove("disabled")
  } else {
    console.log("update non-check")
    checkbox.classList.add("disabled")
  };
};

export const colorchange = () => {
  let checkboxes = editor.querySelectorAll("checkbox")

  checkboxes.forEach(checkbox => {
      checkbox.addEventListener("click", () => {
        console.log("colorchange")
        update(checkbox);
      });
  });
};

