export const showB2bModifyContainer = () => {

  var modifyB2bBtn = document.getElementById('modify-b2b-order-toggle');
  var modifyB2bBtnContainer = document.getElementById('modify-b2b-order-btn-container');
  var modifyB2bContainer = document.getElementById('modify-b2b-order-container');

  if (modifyB2bBtn) {
    modifyB2bBtn.onclick = function(){
      modifyB2bContainer.style.display = "block";
      modifyB2bBtn.style.display = "none";
      modifyB2bBtnContainer.style.display = "none";
    };
  };
};
