// Entry point for the build script in your package.json
import "./controllers"
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

/*import "./channels"
*/
import { Turbo } from "@hotwired/turbo-rails"
Turbo.session.drive = false
import Rails from '@rails/ujs';
Rails.start();
import * as ActiveStorage from "@rails/activestorage"
ActiveStorage.start()


/*Dropzone.autoDiscover = false*/


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

//= require bootstrap-modal
//= require select2

// External imports
import '@fortawesome/fontawesome-free/js/fontawesome'
import '@fortawesome/fontawesome-free/js/solid'
import "chartkick/chart.js"
import "dropzone/dist/dropzone.css";
import "dropzone/dist/basic.css";
import * as bootstrap from "bootstrap";
import flatpickr from "flatpickr";
import "flatpickr/dist/flatpickr.css";
import readmore from 'readmore-js';
window.readmore = readmore

document.addEventListener("turbo:load", () => {
  var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
  var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
    return new bootstrap.Tooltip(tooltipTriggerEl)
  })
  var popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'))
  var popoverList = popoverTriggerList.map(function (popoverTriggerEl) {
    return new bootstrap.Popover(popoverTriggerEl)
  })
})



// Internal imports, e.g:
// import { initSelect2 } from '../components/init_select2';
import { colorchange } from './components/checkbox_if_closed';
import { displayTab } from './components/tabs';
import { displayPill, goToRefundRequests } from './components/pills';
import { customerTableFilter } from './components/customers_table_filter';
import { ordersTableFilter } from './components/orders_table_filter';
import { storesTableFilter } from './components/stores_table_filter';
import { sortingProducts } from './components/sorting';
import { collapseList } from './components/collapsible';
import { retailerValuePropsReadMore, retailerWhyReadMore, retailerFeaturesReadMore, b2bProductPageFeaturesReadMore, b2cProductPageFeaturesReadMore, retailerPageVPropReadMore } from './components/retailers_text_collapsible';
import { retailersCarousel } from './components/retailers_carousel';
import { typePlaceholder } from './components/typing_placeholder';
import { hoverPin } from './components/hover_map_pins';
import { showFilters, showFiltersStore } from './components/show_filters';
import { showStripeContainer } from './components/stripe_container_toggle';
import { selectPaymentMethod, selectDeliveryMethod } from './components/b2b_checkout';
import { selectBillingAddress, selectDeliveryAddress } from './components/b2b_checkout';
import { collapsibleUpcomingRetailerDashboard, collapsiblePastRetailerDashboard, collapsibleRefundsRetailerDashboard } from './components/retailer_dashboard_tabs_collapsible';
import { collapsibleUpcomingCustomerDashboard, collapsiblePastCustomerDashboard, collapsibleRefundsCustomerDashboard } from './components/customer_dashboard_tabs_collapsible';
import { collapsibleSettingsStore, collapsibleSettingsPreorders } from './components/settings_tabs_collapsible';
import { chooseProduct } from './components/choose_product';
import { invoiceLink } from './components/invoice_link';
import { openingHoursValue } from './components/opening_hours';
import { onOffToggleB2c, onOffToggleB2b, onOffToggleVat, onOffToggleDelivery } from './components/on_off_toggle';
import { keepTab, keepSignUpTab, keepDashboardTab, keepCustomerDashboardTab, keepPlanningTab, keepOfferingsTab, keepSettingsTab, keepProductSettingsTab, keepCustomerSettingsTab } from './components/keep_tab';
import { showB2bModifyContainer } from './components/modify_b2b_order_toggle';
import { initializePopover } from './components/popover';
import { displayNewBillingAddressForm, displayNewDeliveryAddressForm } from './components/customer_settings';
import { showWeeklyOrder } from './components/weekly_order';
import { launchAcceptanceModal, launchLimitedOfferModal } from './components/launch_modal';
import { mobileNavBarsExpandable, dropDownSolutionsNavbar, locationsNavExpandable } from './components/navbar';
import { storeDescReadMore } from './components/store_desc_read_more';
import { keepStoreOwnerOfferingsTab, showOfferingEdit } from './components/store_owner_offerings';
import { collapsTableRows } from './components/b2b_pricing_table_collapsible';
import { b2bOfferingsUpdateQuantityToggle } from './components/b2b_offerings_update';
import { disableOfferingQuantity } from './components/product_update_b2b_offerings';
import { initFlatpickr } from './components/init_flatpickr';
import { downloadSpinner } from './components/download_spinner';
import { disableWeeklyCartDestroyBtn } from './components/disable_destroy_btn';
import { forwardWeeklyReminderMail } from './components/add_forward_weekly_reminder_mail';
import { ageVerificationOverlay } from './components/age_verification_overlay';
import { editPriceOptions } from './components/price_options';
import { updateDeliveryInfoWeekdays } from './components/settings_delivery';
// import { checkRetailerTacPrivacyAcceptance } from './components/accept_retailer_tac_privacy';
import './components/cookies';
// import { addToCart } from './components/add_to_cart';



document.addEventListener('turbo:load', () => {
  // Call your functions here, e.g:
  // colorchange();
  // displayTab();
  // displayPill();

  customerTableFilter();
  ordersTableFilter();
  storesTableFilter();
  sortingProducts();
  collapseList();
  retailerValuePropsReadMore();
  retailerWhyReadMore();
  retailerFeaturesReadMore();
  b2bProductPageFeaturesReadMore();
  b2cProductPageFeaturesReadMore();
  retailerPageVPropReadMore();
  typePlaceholder();
  hoverPin();
  showFilters();
  showFiltersStore();
  showStripeContainer();
  selectPaymentMethod();
  selectBillingAddress();
  collapsibleUpcomingRetailerDashboard();
  collapsiblePastRetailerDashboard();
  collapsibleUpcomingCustomerDashboard();
  collapsiblePastCustomerDashboard();
  collapsibleRefundsRetailerDashboard();
  collapsibleRefundsCustomerDashboard();
  collapsibleSettingsStore();
  collapsibleSettingsPreorders();
  chooseProduct();
  keepTab();
  keepSignUpTab();
  keepDashboardTab();
  keepCustomerDashboardTab();
  keepPlanningTab();
  keepOfferingsTab();
  keepSettingsTab();
  keepProductSettingsTab();
  keepCustomerSettingsTab();
  onOffToggleB2c();
  onOffToggleB2b();
  onOffToggleVat();
  onOffToggleDelivery();
  invoiceLink();
  openingHoursValue();
  showB2bModifyContainer();
  selectDeliveryMethod();
  selectDeliveryAddress();
  initializePopover();
  displayNewBillingAddressForm();
  displayNewDeliveryAddressForm();
  showWeeklyOrder();
  launchAcceptanceModal();
  mobileNavBarsExpandable();
  dropDownSolutionsNavbar();
  goToRefundRequests();
  storeDescReadMore();
  locationsNavExpandable();
  keepStoreOwnerOfferingsTab();
  collapsTableRows();
  launchLimitedOfferModal();
  b2bOfferingsUpdateQuantityToggle();
  disableOfferingQuantity();
  initFlatpickr();
  downloadSpinner();
  disableWeeklyCartDestroyBtn();
  forwardWeeklyReminderMail();
  ageVerificationOverlay();
  editPriceOptions();
  updateDeliveryInfoWeekdays();
  showOfferingEdit();
  // checkRetailerTacPrivacyAcceptance();
  // retailersCarousel();
  // addToCart();
});
