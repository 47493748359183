export const collapsibleSettingsStore = () => {
  var settingsStore = document.getElementById("owner-settings-store");
  var storeInfos = document.getElementById("store-infos");
  var bankDetails = document.getElementById("bank-details");
  var vatRate = document.getElementById("vat-rate");
  var photos = document.getElementById("photos");
  var agbPp = document.getElementById("agb-pp");
  var openingHours = document.getElementById("opening-hours");
  var employees = document.getElementById("employees");
  var api = document.getElementById("api");
  var settingsStoreArrowUp = document.getElementById('settings-store-arrow-up');
  var settingsStoreArrowDown = document.getElementById('settings-store-arrow-down');

  if ((storeInfos) && (photos) && (openingHours) && (employees)) {
    settingsStore.addEventListener('click', () => {
      if ((storeInfos) && (photos) && (openingHours) && (employees) && (vatRate) && (agbPp) && (bankDetails) && (api)) {
        if (storeInfos.style.display === "block") {
          storeInfos.style.display = "none";
          bankDetails.style.display = "none";
          vatRate.style.display = "none";
          photos.style.display = "none";
          agbPp.style.display = "none";
          openingHours.style.display = "none";
          employees.style.display = "none";
          api.style.display = "none";
          settingsStoreArrowUp.style.display = "none";
          settingsStoreArrowDown.style.display = "block";
        } else {
          storeInfos.style.display = "block";
          bankDetails.style.display = "block";
          vatRate.style.display = "block";
          photos.style.display = "block";
          agbPp.style.display = "block";
          openingHours.style.display = "block";
          employees.style.display = "block";
          api.style.display = "block";
          settingsStoreArrowUp.style.display = "block";
          settingsStoreArrowDown.style.display = "none";
        };
      };
      if ((storeInfos) && (photos) && (openingHours) && (employees) && (vatRate == null) && (agbPp == null) && (bankDetails == null) && (api == null)) {
        if (storeInfos.style.display === "block") {
          storeInfos.style.display = "none";
          photos.style.display = "none";
          openingHours.style.display = "none";
          employees.style.display = "none";
          settingsStoreArrowUp.style.display = "none";
          settingsStoreArrowDown.style.display = "block";
        } else {
          storeInfos.style.display = "block";
          photos.style.display = "block";
          openingHours.style.display = "block";
          employees.style.display = "block";
          settingsStoreArrowUp.style.display = "block";
          settingsStoreArrowDown.style.display = "none";
        };
      };
    });
  };
};

export const collapsibleSettingsPreorders = () => {
  var settingsPreorders = document.getElementById("owner-settings-preorder");
  var settingsB2cCustomers = document.getElementById("settings-b2c-customers");
  var settingsB2bCustomers = document.getElementById("settings-b2b-customers");
  var settingsPreordersArrowUp = document.getElementById('settings-preorders-arrow-up');
  var settingsPreordersArrowDown = document.getElementById('settings-preorders-arrow-down');
  var settingsNotificationBadge = document.getElementById('settings-noti-preorders-b2b')

  if ((settingsB2cCustomers) || (settingsB2bCustomers)) {
    settingsPreorders.addEventListener('click', () => {
      if ((settingsB2cCustomers) && (settingsB2bCustomers)) {
        if (settingsB2cCustomers.style.display === "block") {
          settingsB2cCustomers.style.display = "none";
          settingsB2bCustomers.style.display = "none";
          settingsPreordersArrowUp.style.display = "none";
          settingsPreordersArrowDown.style.display = "block";
          if (settingsNotificationBadge) {
            settingsNotificationBadge.display = "none";
          };
        } else {
          settingsB2cCustomers.style.display = "block";
          settingsB2bCustomers.style.display = "block";
          settingsPreordersArrowUp.style.display = "block";
          settingsPreordersArrowDown.style.display = "none";
          if (settingsNotificationBadge) {
            settingsNotificationBadge.display = "block";
          };
        };
      };
      // if (settingsB2cCustomers && settingsB2bCustomers == null) {
      //   if (settingsB2cCustomers.style.display === "block") {
      //     settingsB2cCustomers.style.display = "none";
      //     settingsPreordersArrowUp.style.display = "none";
      //     settingsPreordersArrowDown.style.display = "block";
      //   } else {
      //     settingsB2cCustomers.style.display = "block";
      //     settingsPreordersArrowUp.style.display = "block";
      //     settingsPreordersArrowDown.style.display = "none";
      //   };
      // };

      // if (settingsB2bCustomers && settingsB2cCustomers == null) {
      //     if (settingsB2bCustomers.style.display === "block") {
      //       settingsB2bCustomers.style.display = "none";
      //       settingsPreordersArrowUp.style.display = "none";
      //       settingsPreordersArrowDown.style.display = "block";
      //     } else {
      //       settingsB2bCustomers.style.display = "block";
      //       settingsPreordersArrowUp.style.display = "block";
      //       settingsPreordersArrowDown.style.display = "none";
      //     };
      // };
    });
  };
};
