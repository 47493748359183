export const disableWeeklyCartDestroyBtn = () => {
  $(document).on('confirm:complete', '.weekly-b2b-sci-delete-btn', function(e, answer) {
    if (answer) {
        // User confirmed. Prevent further clicks on this link.
        $(this).off('click').on('click', function(e) {
            e.preventDefault();
        });
    }
  });
};
