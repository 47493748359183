export const downloadSpinner = () => {
  $(document).ready(function() {
    // Add a click event listener to your download button (replace #spinner-download-btn-production-overview with the actual button selector)
    $(".spinner-download-btn").on("click", function() {
      // Show the spinner modal when the download button is clicked
      const filename = $(this).data("filename");
      const url = $(this).data("download-url");
      $("#spinnerModal").addClass("show");

      // Initiate the download using AJAX
      $.ajax({
        url: url, // Replace with the actual URL of your download action
        method: "POST",
        xhrFields: {
          responseType: 'blob' // Set the response type to 'blob' to handle binary data
        },
        success: function(data) {
          $("#spinnerModal").removeClass("show");

          // Create a temporary anchor element to trigger the download
          const link = document.createElement("a");
          const url = window.URL.createObjectURL(new Blob([data]));
          link.href = url;
          link.download = filename; // Replace with the desired file name
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);

          // Clean up the temporary URL
          window.URL.revokeObjectURL(url);
        },
        error: function() {
          // Handle any error that might occur during the download (optional)
          alert("An error occurred during the download.");
          $("#spinnerModal").removeClass("show");
        }
      });
    });
  });
};
